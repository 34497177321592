import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoleTarget } from '../../hooks';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClickToCopy from '../../components/ClickToCopy';
import { ReactComponent as EditIcon } from '../../assets/icons/EditIcon.svg';
import EditTitle from './EditTitle';
import Environment from './Environment';
import NewEnvironment from './NewEnvironment';
import {
  reqAddEnvironment,
  reqUpdateTitle,
} from '../../redux-store/modules/titles';
import copy from '../../copy-text';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  editIcon: {
    display: 'flex',
    cursor: 'pointer',
    marginLeft: 10,
    marginTop: 5,
    color: theme.text.secondary,
    opacity: 0.5,
    fontSize: '0.85em',
  },
  envContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  envQty: {
    fontSize: '0.9em',
    fontWeight: 'bold',
  },
  envType: {
    fontSize: '0.8rem',
    fontWeight: 700,
  },
  envTypeGroup: ({ envType }: any) => ({
    backgroundColor: theme.environments.colors[envType],
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 5,
    marginRight: 5,
    padding: '0px 10px',
    borderRadius: 8,
    height: 24,
  }),
  envTypeGroupContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  accordionSummary: {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  envTitle: {
    fontSize: '1.5em',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
  },
  newEnv: {
    color: theme.text.highlight,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 250,
    height: 80,
    border: `${theme.text.highlight} 2px dashed`,
    borderRadius: 10,
    padding: 10,
    marginRight: 20,
    marginTop: 20,
    '& p': {
      fontWeight: 'bold',
      fontSize: '1.1em',
    },
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
      border: `${theme.text.highlight} 2px dashed`,
    },
    '&:active': {
      backgroundColor: 'rgba(0,0,0,0.1)',
      border: `${theme.text.highlight} 2px dashed`,
    },
  },
  title: {
    fontSize: '1.5em',
  },
  titleId: {
    color: theme.text.primary,
    fontSize: '1.1em',
  },
}));

function EnvTypeGroup(props) {
  const { envType, qty } = props;
  const classes = useStyles({ envType });

  return (
    <div className={classes.envTypeGroup}>
      <Typography className={classes.envQty}>{`${qty} ${envType}`}</Typography>
    </div>
  );
}

export default function Title(props) {
  const { title } = props;
  const { id, name } = title;
  const { Titles } = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showEditTitleModal, setShowEditTitleModal] = useState(false);
  const [showNewEnvModal, setShowNewEnvModal] = useState(false);
  const titlesPermissions = useRoleTarget('titles');
  const environmentPermissions = useRoleTarget('environments');

  let envTypes = { ...Titles.environmentTypes };
  Object.keys(envTypes).forEach((envType) => (envTypes[envType].qty = 0));

  const handleAddEnvironmentSubmit = (environment) => {
    setShowNewEnvModal(false);
    dispatch(reqAddEnvironment(environment, title.id));
  };
  const handleEditTitle = (event) => {
    event.stopPropagation();
    setShowEditTitleModal(true);
  };
  const handleEditTitleSubmit = (titleData) => {
    const { id, name } = titleData;
    setShowEditTitleModal(false);
    dispatch(reqUpdateTitle({ id, name }));
  };

  title.environments.forEach((env) => {
    envTypes[env.environmentType].qty++;
  });

  const editTitle = titlesPermissions.UPDATE && (
    <EditIcon className={classes.editIcon} onClick={handleEditTitle} />
  );

  const addEnvironment =
    title.availableEnvironmentTypes.length && environmentPermissions.CREATE ? (
      <div className={classes.newEnv} onClick={() => setShowNewEnvModal(true)}>
        <Typography>{copy.pages.titles.newEnvironment.cta}</Typography>
      </div>
    ) : null;

  return (
    <>
      <EditTitle
        title={title}
        openModal={showEditTitleModal}
        onClick={handleEditTitle}
        onSubmit={handleEditTitleSubmit}
        onModalClose={() => setShowEditTitleModal(false)}
      />
      <NewEnvironment
        envTypes={title.availableEnvironmentTypes}
        openModal={showNewEnvModal}
        onSubmit={handleAddEnvironmentSubmit}
        titleName={title.name}
        onModalClose={() => setShowNewEnvModal(false)}
      />
      <div className={classes.root}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            classes={{ content: classes.accordionSummary }}
            expandIcon={<ExpandMoreIcon />}
          >
            <div>
              <div className={classes.heading}>
                <Typography className={classes.title}>{name}</Typography>
                {editTitle}
              </div>
              <div>
                <ClickToCopy value={id}>
                  <Typography className={classes.titleId}>{id}</Typography>
                </ClickToCopy>
              </div>
            </div>

            <div className={classes.envTypeGroupContainer}>
              {Object.keys(envTypes).map((envType, i) =>
                envTypes[envType].qty ? (
                  <EnvTypeGroup
                    key={i}
                    envType={envType}
                    qty={envTypes[envType].qty}
                  />
                ) : null
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography className={classes.envTitle}>Environments</Typography>
            <div className={classes.envContainer}>
              {addEnvironment}
              {Object.keys(envTypes).map((type) =>
                title.environments
                  .filter((env) => type === env.environmentType)
                  .sort((a, b) => b.createdTimestamp - a.createdTimestamp)
                  .map((env, i) => (
                    <Environment
                      key={i}
                      envData={env}
                      color={envTypes[env.environmentType].color}
                      envType={type}
                      titleName={title.name}
                    />
                  ))
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}
