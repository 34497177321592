import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.appBar.color,
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    '&:visited': {
      color: 'inherit',
    },
  },
  root: {
    color: theme.text.secondary,
    padding: '5px 15px',
    display: 'flex',
    flexDirection: 'column',
    '& a': {
      marginTop: 10,
      marginBottom: 10,
    },
    '& p': {
      color: theme.text.secondary,
    },
  },
}));

const HelpPopover = (props) => {
  const { anchorEl, onClose } = props;
  const classes = useStyles();

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <div className={classes.root}>
        <a target="_new" href={config.links.dev_docs} className={classes.link}>
          <Typography variant="body2">Documentation</Typography>
        </a>
        <a target="_new" href={config.links.api_docs} className={classes.link}>
          <Typography variant="body2">API Reference</Typography>
        </a>
      </div>
    </Popover>
  );
};

export default HelpPopover;
