import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@material-ui/icons/';
import Card from '../../components/Card';
import Button from '../../components/UI/Button';
import Select from '../../components/UI/Select';
import { areEqual, isValidEmail } from '../../utilities';
import { useRoleTarget } from '../../hooks';
import {
  reqAddPartner,
  reqAddPartnerAndAgreement,
  reqUpdatePartner,
} from '../../redux-store/modules/partners';

const useStyles = makeStyles((theme) => ({
  viewContainer: {
    backgroundColor: theme.colors.bgColor1,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    padding: 30,
    position: 'relative',
  },
  column: {
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    width: 350,
    marginRight: 30,
    '&:last-child': {
      marginRight: 0,
    },
  },
  columnRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  header: {
    color: theme.card.header.color,
    backgroundColor: theme.card.header.backgroundColor,
    borderRadius: '5px 5px 0px 0px',
    padding: '10px 20px',
    marginTop: 20,
  },
  formWrapper: {
    backgroundColor: theme.colors.bgColor4,
    borderTop: 'none',
    borderRadius: '0px 0px 5px 5px',
    padding: 20,
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  input: {
    margin: '10px 0px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    '& div:first-child': {
      marginRight: 15,
    },
  },
  checkboxLabel: {
    fontSize: 14,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 30,
    justifyContent: 'flex-end',
  },
  title: {
    color: theme.text.primary,
  },
}));

export default function PartnerForm(props) {
  const { title, partnerData = {} } = props;
  const isNewForm =
    partnerData && partnerData.hasOwnProperty('id') ? false : true;
  let history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const partnersPermissions = useRoleTarget('partners');

  const managers = [
    {
      name: 'Andre Johnson',
      email: 'andre@mythical.games',
    },
    {
      name: 'Kurt',
      email: 'kurt@mythical.games',
    },
  ];

  const blankForm = {
    firstName: '',
    middleName: '',
    lastName: '',
    company: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    notes: '',
    managerName: managers[0].name,
    managerEmail: managers[0].email,
    id: isNewForm ? null : partnerData.id,
    ...partnerData,
  };
  const noErrors = Object.keys(blankForm).reduce(
    (acc, key) => ({ ...acc, [key]: false }),
    {}
  );
  const [formData, setFormData] = useState<any>({ ...blankForm });
  const [useNameAsCompany, setUseNameAsCompany] = useState<any>(false);
  const [hasErrors, setHasErrors] = useState<any>(noErrors);
  const [hasChanges, setHasChanges] = useState<any>(
    !areEqual(blankForm, formData)
  );

  const handleChange = (event) => {
    const updatedFormData = {
      ...formData,
      [event.target.name]: event.target.value,
    };
    setFormData(updatedFormData);
    setHasChanges(!areEqual(blankForm, updatedFormData));
  };
  const handleChangeManager = (event) => {
    const updatedFormData = {
      ...formData,
      managerEmail: event.target.value.email,
      managerName: event.target.value.name,
    };
    setFormData(updatedFormData);
    setHasChanges(!areEqual(blankForm, updatedFormData));
  };
  const handleToggleCheckbox = (event) => {
    setUseNameAsCompany(event.target.checked);
    if (event.target.checked) {
      setFormData({
        ...formData,
        company: `${formData.firstName} ${formData.lastName}`,
      });
    }
  };
  const handleCancel = () => {
    // Clear form
    setFormData(blankForm);
    setHasErrors(noErrors);
    history.goBack();
  };
  const handleSaveNew = () => {
    if (validate(formData)) {
      // submit
      dispatch(reqAddPartner(formData));
      history.push('/partners');
    }
  };
  const handleSaveChanges = () => {
    if (validate(formData)) {
      // submit
      dispatch(reqUpdatePartner(formData));
      history.push('/partners');
    }
  };
  const handleSaveAndAgreement = () => {
    if (validate(formData)) {
      // submit
      dispatch(reqAddPartnerAndAgreement(formData));
    }
  };
  const validate = (data) => {
    const validEmail = isValidEmail(data.email);
    if (
      data.firstName.length &&
      data.lastName.length &&
      data.company.length &&
      validEmail
    ) {
      setHasErrors(noErrors);
      return true;
    } else {
      setHasErrors({
        ...hasErrors,
        firstName: data.firstName.length ? false : true,
        lastName: data.lastName.length ? false : true,
        company: data.company.length ? false : true,
        email: !validEmail,
        managerName: data.managerName.length ? false : true,
      });
      return false;
    }
  };

  const general = (
    <div className={classes.column}>
      <div className={classes.header}>
        <Typography>General</Typography>
      </div>
      <Card className={classes.formWrapper}>
        <TextField
          className={classes.input}
          error={hasErrors.firstName}
          label="First Name"
          name="firstName"
          helperText="*Required"
          value={formData.firstName}
          onChange={handleChange}
        />
        <TextField
          className={classes.input}
          label="Middle Name"
          name="middleName"
          value={formData.middleName}
          onChange={handleChange}
        />
        <TextField
          className={classes.input}
          error={hasErrors.lastName}
          label="Last Name"
          name="lastName"
          helperText="*Required"
          value={formData.lastName}
          onChange={handleChange}
        />
        <TextField
          className={classes.input}
          error={hasErrors.company}
          label="Company"
          name="company"
          helperText="*Required"
          value={formData.company}
          onChange={handleChange}
        />
        {isNewForm ? (
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                value={useNameAsCompany === 'true' ? true : false}
                onChange={handleToggleCheckbox}
              />
            }
            classes={{ label: classes.checkboxLabel }}
            label="Company same as first and last name"
          />
        ) : (
          <TextField
            disabled
            multiline
            className={classes.input}
            label="Partner ID"
            name="id"
            value={partnerData.id}
          />
        )}
      </Card>
    </div>
  );

  const contact = (
    <div className={classes.column}>
      <div className={classes.header}>
        <Typography>Contact</Typography>
      </div>
      <Card className={classes.formWrapper}>
        <TextField
          className={classes.input}
          error={hasErrors.email}
          label="Email"
          name="email"
          helperText="*Required"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          className={classes.input}
          label="Phone Number"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
        />
        <TextField
          className={classes.input}
          label="Address Line 1"
          name="address1"
          value={formData.address1}
          onChange={handleChange}
        />
        <TextField
          className={classes.input}
          label="Address Line 2"
          name="address2"
          value={formData.address2}
          onChange={handleChange}
        />
        <TextField
          className={classes.input}
          label="City"
          name="city"
          value={formData.city}
          onChange={handleChange}
        />
        <TextField
          className={classes.input}
          label="State"
          name="state"
          value={formData.state}
          onChange={handleChange}
        />
        <div className={classes.row}>
          <TextField
            className={classes.input}
            label="Zipcode"
            name="zip"
            value={formData.zip}
            onChange={handleChange}
          />
          <TextField
            className={classes.input}
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleChange}
          />
        </div>
      </Card>
    </div>
  );

  const acctManager = (
    <div className={classes.column}>
      <div className={classes.column}>
        <div className={classes.header}>
          <Typography>Account Manager</Typography>
        </div>
        <Card className={classes.formWrapper}>
          <Select
            className={classes.input}
            label="Account Manager"
            error={hasErrors.managerName}
            name="manager"
            value={
              managers.find(
                (manager) => manager.name === formData.managerName
              ) || {}
            }
            onChange={handleChangeManager}
            options={managers.map((manager, i) => ({
              value: manager,
              label: manager.name,
            }))}
          />
        </Card>
      </div>
      <div className={classes.column}>
        <div className={classes.header}>
          <Typography>Additional Notes</Typography>
        </div>
        <Card className={classes.formWrapper}>
          <TextField
            fullWidth
            multiline
            className={classes.input}
            label="Additional Notes"
            name="notes"
            helperText={
              formData.notes.length
                ? `${formData.notes.length}/500 character limit`
                : '500 character limit'
            }
            value={formData.notes}
            onChange={handleChange}
            inputProps={{ maxLength: 500 }}
          />
        </Card>
      </div>
    </div>
  );

  const actions =
    isNewForm && partnersPermissions.CREATE ? (
      <div className={classes.actions}>
        <Button type="contained" label="Save" onClick={handleSaveNew} />
        <Button
          type="contained"
          label="Save and + Agreement"
          onClick={handleSaveAndAgreement}
        />
        <Button label="Cancel" onClick={handleCancel} />
      </div>
    ) : (
      <div className={classes.actions}>
        {hasChanges && partnersPermissions.UPDATE && (
          <>
            <Button
              type="contained"
              label="Save Changes"
              onClick={handleSaveChanges}
            />
            <Button label="Cancel" onClick={handleCancel} />
          </>
        )}
      </div>
    );

  return (
    <div className={classes.viewContainer}>
      <Typography className={classes.title}>{title}</Typography>
      <div className={classes.columnRow}>
        {general}
        {contact}
        {acctManager}
      </div>
      {actions}
    </div>
  );
}
